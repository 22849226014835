<template>
  <section>
    <loading v-bind:dialog="isLoading" v-bind:texto="'Buscando pedidos en la base de datos'"></loading>
    <v-form ref="form" v-on:submit.prevent="Buscar">
      <v-layout row wrap>
        <v-flex xs6 sm6 lg3>
          <v-text-field
            label="Fecha desde"
            append-icon="event"
            type="date"
            v-model="fecini"
            autofocus
            :rules="feciniRules"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 sm6 lg3>
          <v-text-field
            label="Fecha hasta"
            append-icon="event"
            type="date"
            v-model="fecfin"
            :rules="fecfinRules"
          ></v-text-field>
        </v-flex>
        <v-flex xs5 sm4 lg2>
          <v-btn
            type="submit"
            color="black"
            outline
            block
            :disabled="isLoading"
          >
            <v-icon>search</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <v-layout row wrap>
      <v-flex lg12>
        <alert
          v-bind:tipo="notificacion.tipo"
          v-bind:mensaje="notificacion.mensaje"
        ></alert>
        <v-spacer></v-spacer>
        <v-card v-show="!isLoading">
          <v-toolbar card color="white">
            <v-text-field
              flat
              solo
              disabled
              v-model="search"
              label="Listado de pedidos"
              single-line
              hide-details
            ></v-text-field>
            <v-menu offset-y :nudge-left="170" :close-on-content-click="false">
              <v-btn icon slot="activator">
                <v-icon>more_vert</v-icon>
              </v-btn>
              <v-list>
                <v-list-tile
                  v-for="item in headers"
                  :key="item.value"
                  @click="changeSort(item.value)"
                >
                  <v-list-tile-title>
                    {{ item.text }}
                    <v-icon v-if="pagination.sortBy === item.value">{{
                      pagination.descending ? "arrow_downward" : "arrow_upward"
                    }}</v-icon>
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout v-resize="onResize" column>
              <v-data-table
                :headers="headers"
                :items="pedidos"
                :search="search"
                :pagination.sync="pagination"
                :hide-headers="isMobile"
                :class="{ mobile: isMobile }"
                :rows-per-page-items="rowsperpageitems"
              >
                <template slot="items" slot-scope="props">
                  <tr v-if="!isMobile">
                    <td>{{ props.item.ot0_numero }}</td>
                    <td>{{ props.item.ot0_fecemi | fecha }}</td>
                    <td>{{ props.item.ote_descri }}</td>
                    <td>{{ props.item.ot0_imptot | moneda }}</td>
                    <td>
                      <v-btn
                        color="primary"
                        :to="'/Pedidos/Ver/' + props.item.ot0_codigo"
                        fab
                        small
                        dark
                        title="Ver Pedido"
                      >
                        <v-icon>info</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-else>
                    <td>
                      <ul class="flex-content">
                        <li class="flex-item" data-label="Número">
                          {{ props.item.ot0_numero }}
                        </li>
                        <li class="flex-item" data-label="Emitida">
                          {{ props.item.ot0_fecemi| fecha }}
                        </li>
                        <li class="flex-item" data-label="Estado">
                          {{ props.item.ot0_estado }}
                        </li>
                        <li class="flex-item" data-label="Importe ($)">
                          {{ props.item.ot0_imptot | moneda }}
                        </li>
                        <li class="flex-item" data-label="Acciones">
                          <v-btn
                            color="primary"
                            :to="'/Pedidos/Ver/' + props.item.ot0_codigo"
                            fab
                            small
                            dark
                            title="Ver Pedido"
                          >
                            <v-icon>info</v-icon>
                          </v-btn>
                        </li>
                        <li class="enter"></li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import DataTableMixin from "@/mixins/DataTableMixin";
import alert from "@/components/alert/alert.vue";
import loading from "@/components/share/Loading.vue";
import odtServices from "@/services/OrdenesTrabajoServices";
import utils from '@/plugins/Utils.js';
export default {
  name: "ConsultarPedidos",
  mixins: [DataTableMixin,utils.mixins],
  components: { alert, loading },

  data() {
    return {
      pedidos: [],
      fecini: utils.fechaRestar(3, 'month'),
      fecfin: utils.fechaActual,
      isLoading: false,
      notificacion: { mensaje: "", tipo: "error" },
      pagination: { sortBy: "ot0_numero", 'descending': true},
      search: "",
      isMobile: false,
      headers: [
        { text: "Número", align: "left", value: "ot0_numero" },
        { text: "Emitida", value: "ot0_fecemi" },
        { text: "Estado", value: "ot0_estado" },
        { text: "Imp. Total", value: "ot0_imptot" },
        { text: "Acciones", value: "-" }
      ],
      feciniRules: [
        v => !!v || "Seleccione una fecha de inicio.",
        v =>
          v <= this.fecfin ||
          "La fecha de inicio deber ser menor o igual a la de fin."
      ],
      fecfinRules: [
        v => !!v || "Seleccione una fecha de fin.",
        v =>
          v >= this.fecini ||
          "La fecha de fin deber ser mayor o igual a la de inicio."
      ]
    };
  },
  created() {
    this.cargar();
  },
  methods: {
    cargar() {
      this.isLoading = true;
      odtServices
        .getAll()
        .then(res => {
          let codant = 0;
          this.notificacion.mensaje = "";
          this.pedidos = [];
          for (let i = 0; i < res.length; i++) {
            if (
              this.filtarPorFecha(this.fecini, this.fecfin, res[i].ot0_fecemi) &&
              res[i].ot0_codigo != codant
            ) {
              codant = res[i].ot0_codigo;
              this.pedidos.push(res[i]);
            }
          }
        })
        .catch(err => {
          this.notificacion.mensaje =
            "Ocurrio un error al intentar recuperar los registros.";
          if (
            err.response &&
            err.response.data.hasOwnProperty("errorMessage")
          ) {
            this.notificacion.mensaje = err.response.data.errorMessage;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    Buscar() {
      this.cargar();
    },
  }
};
</script>
